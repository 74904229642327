<template>
  <div>
    <el-row class="not-result">
      <span style="position: relative; bottom: 0; color:red; font-size: 20px">
        抱歉, 此{{textObject.content}}仅供 VIP 查看, 您还不是 VIP~
      </span>
    </el-row>
    <el-row class="not-result">
      <el-col :md="24" class="movie-list">
        <el-col :md="12">
          <el-button
            size="medium"
            type="warning"
            @click="handleAck(textObject.route)">看看别的</el-button>
        </el-col>
        <el-col :md="12">
          <el-button
            size="medium"
            type="success"
            @click="handleVip">开通 VIP</el-button>
        </el-col>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'PermissionDeniedCard',
  props: {
    textObject: {
      type: Object,
      default: null
    },
  },
  filters: {
  },
  data() {
    return {
    }
  },
  methods: {
    handleAck(route) {
      this.$router.push(route)
    },
    handleVip() {
      this.$router.push('/vip')
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

.not-result {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}
</style>
