import { get, post } from '@/utils/request'

const commentApi = {
  videoCommentApi: '/api/comment/video',
  videoChildCommentApi: '/api/comment/video/child'
}

// 发布评论
export function publishComment(data) {
  return post(commentApi.videoCommentApi, data)
}

// 获取评论
export function getComment(videoId, pageNumber) {
  return get(commentApi.videoCommentApi + '?videoId=' + videoId + '&pageNumber=' + pageNumber)
}

// 获取评论的子评论
export function getChildComment(commentId, pageNumber) {
  return get(commentApi.videoChildCommentApi + '?commentId=' + commentId + '&pageNumber=' + pageNumber)
}
